@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-family: 'Roboto', sans-serif;
  font-size: 95%;
  font-size: small;
}


body {
  max-width: 100%;
  margin: 0;
  padding: 0;
  color: #4C4C4C;
  font-size: 16px;
  font-weight: normal;
  background-color: #fff;
  overflow-x: hidden;
}

a,
a:hover {
  text-decoration: none !important;
}



.btn.big {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 18px 20px 14px;
  color: #0b0b0b;
  font-size: 21px;
  font-weight: bolder;
  text-transform: uppercase;
  background-color: #F89F04;
  box-shadow: inset 0 4px 10px #FCCF2B, 0 4px 1px #8E590D;
}


.btn.small {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 5px 12px 4px;
  color: #0b0b0b;
  font-size: 12px;
  font-weight: bold;
  background-color: #F89F04;
  box-shadow: inset 0 4px 10px #FCCF2B, 0 4px 1px #8E590D;
}

.theme {
  color: #125EA5;
}

.center {
  text-align: center;
}

.white {
  background-color: #fff;
}

.white-text {
  color: #fff !important;
}

.flex {
  display: flex;
  flex-flow: row wrap;
}

.v-center {
  align-items: center;
}

.r-10 {
  border-radius: 10px;
}

.pb-1 {
  padding-bottom: 5px !important;
}

.master {
  margin-left: -30px;
}

.text-left,
.text-left * {
  text-align: left;
}

.h-bettwen {
  justify-content: space-between;
}

.v-center {
  align-items: center;
}

img {
  max-width: 100%;
}

#intro {
  position: relative;
  margin-bottom: -340px;
  height: 100vh;
  background-position: center top;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}

section {
  width: 100%;
  padding: 40px 0;
}

#about {
  position: relative;
  padding-top: 240px;
  z-index: 1;
}


#details {
  display: flex;
  align-items: center;
  height: 450px;
  padding: 40px 0;
  background-color: #444;
  background-blend-mode: multiply;
  background-attachment: fixed;
}

#materials {
  height: 660px;
  background-color: #fff;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
}

#why {
  padding-top: 34vh;
  height: 160vh;
  background-color: #fff;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#begin {
  min-height: 80vh;
  padding: 40px 0;
  background-color: #fff;
}

#gallery .first-line {
  margin-top: 40px;
}

footer {
  padding: 40px 0;
  background-color: #fff;
}


/* Elements */

.box {
  padding: 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, .25);
}

.box.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  width: 100%;
  max-width: 267px;
  height: 192px;
  background-color: #1E2629;
}

.box.figure .content {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  border: 2px solid #d9d9d9;
  z-index: 0;
}

.box.figure::after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: url('assets/img/svg/down.png') center top 4px no-repeat, url('assets/img/svg/up.png') center bottom 4px no-repeat;
  z-index: 100;
}

.box.figure .small-title {
  padding-bottom: 8px;
  color: #4291D7;
  font-size: 16px;
  line-height: 24px;
  font-weight: medium;
}

.box.figure .text .big {
  color: #fff;
  font-size: 30px;
  line-height: 24px;
  font-weight: medium;
}

.box.figure .text small {
  padding: 0 6px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}


.box.one {
  display: flex;
  height: 520px;
  box-shadow: none;
}

.box.one .content {
  max-width: 308px;
  padding: 80px 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.box.one .content img {
  margin-top: -190px;
}

.box.one.v-start {
  padding-top: 50px;
  align-items: flex-start;
}

.box.one.v-center {
  align-items: center;
}

.box.one.v-end {
  padding-top: 20px;
  align-items: flex-end;
}

.box.two {
  padding: 0;
  box-shadow: none;
}

.box.two * {
  text-align: left;

}

.box.two .content {
  width: 100%;
  max-width: 278px;
  min-height: 272px;
  margin: -30px auto;
  padding: 48px 20px 24px;
  border-top: 4px solid rgba(66, 145, 215, .5);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
}

.box.two img {
  margin-left: 15px;
}

.cta-sub {
  margin: 20px auto;
  color: #030303;
  font-size: 30px;
  font-weight: bold;
}

.cta-sub.last {
  padding-bottom: 24px;
}

.cta-big {
  margin: 20px auto;
  color: #4291D7;
  font-size: 100px;
  font-weight: bolder;
  line-height: 100px;
}

.cta-small {
  width: 40%;
  margin: 20px auto;
  padding-top: 24px;
  color: #030303;
  font-size: 16px;
  font-weight: medium;
  line-height: 24px;
  border-top: 3px dashed #F0F0F0;
}

#price .content {
  width: 100%;
  margin-top: 24px;
  max-width: 350px;
  padding: 24px;
}

#price .content.active {
  position: relative;
  border: 5px solid #86D11A;
  z-index: 1;
}

#price .content.active::after {
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  width: 164px;
  height: 166px;
  content: '';
  background: url('assets/img/icons/corner.png');
  z-index: 100;
}

#price .box-title {
  margin: 20px auto;
  color: #303030;
  font-size: 34px;
  font-weight: bolder;
  line-height: px;
}

#price .box-subtitle {
  margin: 20px auto;
  color: #303030;
  font-size: 21px;
  font-weight: bold;
}

#price .box-text {
  margin: 20px auto;
  color: #3a3a3a;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}

#why .flex {
  margin: 40px 0;
}

#why .flex p {
  width: 80%;
}

.why-title {
  position: relative;
  width: 100%;
  padding: 30px 0 40px 23%;
  background: url('assets/img/Border.png') left top no-repeat;
  background-size: 74%;
  overflow: visible !important;
  z-index: 1;
}

.why-title::before {
  position: absolute;
  top: 40px;
  left: -200px;
  display: block;
  width: 200px;
  height: 100px;
  content: '';
  background: url('assets/img/Curve.png') right bottom no-repeat;
  z-index: 100;
}

.why-title::after {
  position: absolute;
  top: 128px;
  left: -210px;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  border-radius: 10px;
  background-color: #4291D7;
  z-index: 200;
}

.why-subtitle {
  font-size: 21px;
  font-weight: bold;
}

.phone-pic {
  display: block;
  margin-top: 100px;
  margin-right: -40px;
  transform: translateX(15%);
}

#why .flex img {
  margin-left: 0;
  margin-right: 15px;
  width: 10%;
}

#how .how .box-title {
  padding-top: 24px;
}

#gallery .gallery-item:nth-child(even) {
  padding: 40px 0 0;
  background-color: #f0f0f0;
}

#gallery .gallery-item:nth-child(odd) {
  padding: 40px 0 0;
}

#gallery img {
  width: 100%;
  height: 160px;
  margin-bottom: 30px;
}

#gallery .figure * {
  text-align: left;
}

#gallery .figure {
  margin-bottom: 30px;
  width: 100%;
  height: 160px;
  position: relative;
}

#gallery .figure.big {
  width: 100%;
  height: 350px;
  position: relative;
  z-index: 1;
}

#gallery .figure.big img {
  width: 100%;
  height: 350px !important;
  position: absolute;
  z-index: 2;
}

#gallery .figure.big .overlay .content {
  padding: 20px;
  text-align: left;
}

#gallery .figure .overlay {
  opacity: 0;
  padding: 10px;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(11, 11, 11, .75) url('assets/img/icons/zoom.png') center center no-repeat;
  z-index: 3;
  transition: .5s ease-out;
}

#gallery .figure .overlay:hover {
  opacity: 1;
  cursor: pointer !important;
}

#gallery .figure.big .overlay {
  background: rgba(11, 11, 11, .75);
}

#gallery .figure .overlay .content {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #4291D7;
}

#how .how {
  width: 94%;
  margin: 64px auto 24px;
}

.m-quote {
  position: relative;
  z-index: 1;
}

.m-quote::after {
  position: absolute;
  bottom: -84px;
  left: 140px;
  display: block;
  width: 93px;
  height: 84px;
  content: '';
  z-index: 100;
  background: url('assets/img/icons/quote.png') no-repeat;
}

.text-left {
  text-align: left !important;
}

footer {
  margin: 0;
  padding: 40px 0 0;
}

footer .form input::placeholder,
footer .form textarea::placeholder {
  font-style: italic;
}

footer .form textarea {
  min-height: 120px;
}

.recall {
  color: #4291D7;
  padding-bottom: 4px;
  border-bottom: 1px dashed #4291D7;
}

.recall:hover {
  text-decoration: none !important;
}

.footer-bottom {
  margin-top: 40px;
  padding: 50px 0;
  height: 150px;
  background-color: #1E2629;
  border-top: 4px solid #4291D7;
}


.logo-footer img {
  float: left;
  padding-right: 10px;
}


.logo-footer .right img {
  float: right !important;
  text-align: right;
  display: inline-block;
}

footer span.pl-2 {
  display: inline-block;
  padding-top: 15px;
}

.tlps {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.tlp.left,
.tlp.right {
  position: absolute;
  display: block;
  width: 300px !important;
  padding: 15px 15px 15px 25px;
  color: #fff;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .65);
  z-index: 100;
}

.tlp.left {
  left: 0;
}

.tlp.right {
  right: 0;
}

.tlp.left::after {
  position: absolute;
  display: block;
  bottom: 0;
  left: -8.99px;
  width: 16px;
  height: 19px;
  content: '';
  background: url('assets/img/svg/corner-to-left.png') no-repeat;
  z-index: 200;
}

.tlp.right::after {
  position: absolute;
  display: block;
  bottom: 0;
  left: -8.99px;
  width: 16px;
  height: 19px;
  content: '';
  background: url('assets/img/svg/corner-to-left.png') no-repeat;
  z-index: 200;
}

.right-menu {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 10px 25px 10px 15px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, .5);
}

#intro .text-right {
  float: right;
}

#logo {
  display: flex;
  align-items: center;
}

#logo p {
  margin-left: 10px;
  padding: 12px 0 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

.intro-subtitle {
  margin: 0;
  padding: 0;
  color: #0b0b0b;
  font-size: 42px;
  font-weight: normal;
  line-height: 18px;
}

.intro-title {
  margin: 0;
  padding: 0;
  color: #125EA5;
  font-size: 80px;
  font-weight: bold;
  line-height: 84px;
}

.intro-small {
  margin: 20px 0 0 20px;
  padding: 0;
  color: #4C4C4C;
  font-size: 22px;
  font-weight: normal;
  line-height: 28px;
}

.phone-intro {
  margin: 0;
  padding: 0;
  color: #030303;
  font-size: 24px;
  font-weight: normal;
  line-height: 18px;
}

#intro .btn {
  margin: 30px 0;
}

.right-menu .phone {
  margin-right: 15px;
  color: #030303;
  font-size: 24px;
  font-weight: bold;
}

/* Typography */

.section-title {
  color: #030303;
  font-size: 46px;
  font-weight: bold;
  text-align: center;
}

.section-subtitle {
  max-width: 74%;
  margin: 32px auto 24px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

#materials .section-subtitle {
  max-width: 94%;
  margin-bottom: 40px;
}

.box-title {
  color: #030303;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
}

.box-subtitle {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.box-text {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}
